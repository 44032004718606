html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*-- placeholder color --*/
::-webkit-input-placeholder {
  color: #666; }

::-moz-placeholder {
  color: #666; }

:-moz-placeholder {
  color: #666; }

:-ms-input-placeholder {
  color: #666; }

* {
  -webkit-text-size-adjust: none; }

*:focus, select:focus, input:focus, textarea:focus {
  outline: none; }

body {
  font-family: "Calibri";
  font-size: 19px;
  color: #000;
  min-width: 1253px; }

select, input, textarea {
  font-family: "Calibri";
  font-size: 19px;
  color: #000;
  -webkit-appearance: none; }

.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  .header--absolute {
    position: absolute;
    background-color: inherit; }
    .header--absolute .header__wrapper {
      width: 950px;
      padding: 34px 20px 0; }
  .header--fixed {
    position: fixed;
    background-color: #fff; }
    .header--fixed .header__wrapper {
      width: 750px;
      padding: 15px 20px; }
  .header__wrapper {
    *zoom: 1;
    margin: 0 auto; }
    .header__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .header__logo {
    float: left;
    margin-right: 35px; }
  .header__info {
    *zoom: 1;
    height: 40px;
    margin: 40px 0 14px; }
    .header__info:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .header__adress {
    float: left;
    font-size: 17px;
    margin-right: 60px; }
  .header__phone {
    text-decoration: none;
    color: inherit;
    font-size: 33px;
    font-weight: bold; }
  .header__distinguish {
    color: #f60; }
  .header__navigation {
    font-size: 22px; }
  .header__item {
    text-decoration: none;
    color: inherit;
    margin-right: 32px; }
    .header__item:last-child {
      margin-right: 0; }

.footer {
  background-image: url("/images/footer_bg.jpg"); }
  .footer__wrapper {
    *zoom: 1;
    width: 805px;
    margin: 0 auto;
    padding: 36px 20px 31px; }
    .footer__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .footer__logo {
    float: left;
    display: inline-block;
    border-right: 1px solid #a4a3a3;
    line-height: 67px;
    margin-right: 18px;
    padding-right: 17px; }
    .footer__logo img {
      vertical-align: middle; }
  .footer__copyright {
    float: left;
    color: #a4a3a3; }
  .footer__development {
    float: right;
    line-height: 67px; }
    .footer__development img {
      vertical-align: middle; }

.bold {
  font-weight: bold; }

.slick-dots {
  *zoom: 1;
  position: absolute;
  bottom: 93px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5; }
  .slick-dots:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both; }
  .slick-dots li {
    float: left;
    font-size: 0;
    background-color: #f6cd0c;
    cursor: pointer;
    width: 23px;
    height: 5px;
    margin: 0 6.5px;
    transition-duration: 0.3s; }
    .slick-dots li.slick-active {
      background-color: #f60; }
    .slick-dots li button {
      display: none; }

.slider {
  position: relative; }
  .slider__arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    width: 38px;
    height: 74px;
    z-index: 6; }
    .slider__arrow--prev {
      left: 50px;
      background-image: url("/images/ico/left_arrow.png"); }
    .slider__arrow--next {
      right: 50px;
      background-image: url("/images/ico/right_arrow.png"); }
  .slider__item {
    position: relative; }
  .slider__img {
    width: 100%; }
  .slider__wrapper {
    *zoom: 1;
    position: absolute;
    bottom: 135px;
    left: 50%;
    transform: translateX(-50%);
    width: 897px; }
    .slider__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .slider__info {
    position: relative;
    float: right;
    background-color: rgba(0, 0, 0, 0.54);
    width: 580px;
    padding: 54px 61px 35px;
    box-sizing: border-box;
    z-index: 5; }
    .slider__info:after {
      content: '';
      position: absolute;
      bottom: -17px;
      left: 0;
      background-image: url("/images/slide_info_border.png");
      width: 100%;
      height: 17px; }
  .slider__title {
    font-size: 33px;
    font-weight: bold;
    color: #fddf10;
    margin-bottom: 18px; }
  .slider__text {
    color: #fff; }

.reasons {
  position: relative;
  background-color: #fddf10; }
  .reasons:after {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    background-image: url("/images/reasons_border.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 50px; }
  .reasons__wrapper {
    width: 860px;
    margin: 0 auto;
    padding: 76px 0 111px; }
  .reasons__title {
    position: relative;
    font-size: 33px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 57px; }
    .reasons__title:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-330px, -50%);
      background-color: #000;
      width: 127px;
      height: 2px; }
    .reasons__title:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(330px, -50%);
      background-color: #000;
      width: 127px;
      height: 2px; }
  .reasons__list {
    *zoom: 1; }
    .reasons__list:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .reasons__item {
    float: left;
    width: 187px;
    margin-right: 68px; }
    .reasons__item:nth-child(2n+1) {
      width: 140px; }
    .reasons__item:last-child {
      margin-right: 0; }
  .reasons__img {
    display: inline-block;
    text-align: center;
    width: 100%;
    line-height: 100px;
    margin-bottom: 23px; }
    .reasons__img img {
      vertical-align: bottom; }

.about {
  background-image: url("/images/today_bg.jpg"); }
  .about__wrapper {
    *zoom: 1;
    width: 897px;
    margin: 0 auto;
    padding: 93px 0 102px; }
    .about__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .about__img {
    float: left; }
  .about__title {
    font-size: 33px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 48px; }
  .about__info {
    padding-left: 306px; }
  .about__text {
    margin-bottom: 20px; }
  .about__contacts {
    *zoom: 1; }
    .about__contacts:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .about__adress {
    position: relative;
    float: left;
    font-size: 17px;
    padding: 9.5px 0 9.5px 56px; }
    .about__adress:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("/images/ico/point.png");
      width: 40px;
      height: 53px; }
  .about__phone {
    text-decoration: none;
    color: inherit;
    float: right;
    font-size: 32px;
    font-weight: bold;
    line-height: 53px; }
  .about__distinguish {
    color: #009414; }

.services {
  *zoom: 1;
  position: relative;
  overflow: hidden; }
  .services:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both; }
  .services__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    min-width: 600px;
    min-height: 100%; }
    .services__img--right {
      left: inherit;
      right: 0; }
  .services__info {
    position: relative;
    float: right;
    color: #fff;
    width: 55%;
    min-width: 645px;
    z-index: 1; }
    .services__info--green {
      background-color: #009414; }
    .services__info--black {
      float: left;
      background-color: #000; }
    .services__info--yellow {
      background-color: #fddf10;
      color: #000; }
    .services__info--dark {
      float: left;
      background-color: #2a2a2a; }
  .services__wrapper {
    *zoom: 1;
    width: 529px;
    padding: 104px 58px 105px; }
    .services__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
    .services__wrapper--left {
      float: right; }
  .services__title {
    font-size: 33px;
    font-weight: bold;
    color: #ff0;
    margin-bottom: 47px; }
    .services__title--black {
      color: #000; }
  .services__text {
    font-size: 16.6px;
    margin-bottom: 18px; }
  .services__list {
    font-size: 16.6px;
    margin-bottom: 18px; }
  .services__button {
    display: inline-block;
    border: 1px solid #ff0;
    font-size: 19.5px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #ff0;
    width: 274px;
    margin-top: 22px;
    padding: 24px 0; }
    .services__button--black {
      border-color: #000;
      color: #000; }
    .services__button--white {
      position: relative;
      top: 15px;
      border-color: #fff;
      color: #fff; }

.catalog {
  *zoom: 1;
  position: relative;
  overflow: hidden; }
  .catalog:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both; }
  .catalog__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1600px;
    min-height: 100%; }
    .catalog__img--right {
      left: inherit;
      right: 0; }
    .catalog__img--small {
      min-width: 45%;
      width: auto;
      right: 0;
      left: inherit; }
  .catalog__info {
    position: relative;
    float: right;
    color: #000;
    width: 55%;
    min-width: 645px;
    z-index: 1; }
    .catalog__info--left {
      float: left; }
    .catalog__info--white {
      background-color: rgba(255, 255, 255, 0.9); }
      .catalog__info--white:before {
        content: '';
        position: absolute;
        top: 0;
        left: -103px;
        background-image: url("/images/border_white.png");
        background-size: 100% 100%;
        width: 103px;
        height: 100%; }
    .catalog__info--red {
      background-color: rgba(189, 9, 0, 0.92); }
      .catalog__info--red:before {
        content: '';
        position: absolute;
        top: 0;
        right: -103px;
        background-image: url("/images/border_red.png");
        background-size: 100% 100%;
        width: 103px;
        height: 100%; }
      .catalog__info--red .catalog__wrapper {
        float: right; }
    .catalog__info--green {
      background-color: #009414; }
      .catalog__info--green:before {
        content: '';
        position: absolute;
        top: 0;
        right: -101px;
        background-image: url("/images/border_green.png");
        background-size: 100% 100%;
        width: 101px;
        height: 100%; }
      .catalog__info--green .catalog__wrapper {
        float: right; }
  .catalog__wrapper {
    *zoom: 1;
    position: relative;
    width: 529px;
    padding: 94px 20px 75px 156px; }
    .catalog__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .catalog__title {
    font-size: 33px;
    font-weight: bold;
    margin-bottom: 55px; }
    .catalog__title--yellow {
      color: #ffff00; }
    .catalog__title--white {
      color: #fff; }
    .catalog__title--second {
      margin: 35px 0 31px; }
  .catalog__emb {
    position: absolute;
    top: 130px;
    right: 220px; }
  .catalog__item {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 10px;
    padding-left: 35px; }
    .catalog__item:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-image: url("/images/ico/catalog_black.png");
      width: 20px;
      height: 20px; }
    .catalog__item--white {
      color: #fff; }
      .catalog__item--white:before {
        background-image: url("/images/ico/catalog_white.png"); }
  .catalog__link {
    text-decoration: none;
    color: inherit; }
    .catalog__link:hover {
      text-decoration: underline; }
  .catalog__button {
    display: inline-block;
    border: 1px solid #000;
    font-size: 19.5px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #000;
    width: 274px;
    margin-top: 53px;
    padding: 24px 0; }
    .catalog__button--light {
      border-color: #fff;
      color: #ff0; }

.c-list {
  *zoom: 1;
  font-size: 16.6px;
  color: #fff; }
  .c-list:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both; }

.c-item {
  position: relative;
  float: left;
  width: 50%;
  margin-bottom: 10px;
  padding-left: 34px;
  box-sizing: border-box; }
  .c-item:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-image: url("/images/ico/calculation.png");
    width: 13px;
    height: 13px; }

.c-link {
  text-decoration: none;
  color: inherit; }
  .c-link:hover {
    text-decoration: underline; }

.partners__wrapper {
  width: 1253px;
  margin: 0 auto;
  padding: 125px 0 86px; }

.partners__title {
  position: relative;
  font-size: 33px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 72px; }
  .partners__title:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-280px, -50%);
    background-color: #000;
    width: 127px;
    height: 2px; }
  .partners__title:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(280px, -50%);
    background-color: #000;
    width: 127px;
    height: 2px; }

.partners__slider {
  position: relative;
  padding: 0 113px;
  box-sizing: border-box; }

.partners__img {
  margin: 0 auto; }

.partners__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 63px;
  height: 35px; }
  .partners__arrow--prev {
    left: 0;
    background-image: url("/images/ico/slider_arrow_left.jpg");
    width: 61px; }
  .partners__arrow--next {
    right: 0;
    background-image: url("/images/ico/slider_arrow_right.jpg"); }

.callback {
  text-align: center;
  background-image: url("/images/callback_bg.jpg");
  margin: 0 auto;
  padding: 88px 0 100px; }
  .callback__title {
    position: relative;
    font-size: 33px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 28px; }
    .callback__title:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-350px, -50%);
      background-color: #fff;
      width: 127px;
      height: 2px; }
    .callback__title:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(350px, -50%);
      background-color: #fff;
      width: 127px;
      height: 2px; }
  .callback__caption {
    font-weight: bold;
    color: #fefe00;
    margin-bottom: 49px; }
  .callback__form {
    *zoom: 1;
    width: 967px;
    margin: 0 auto; }
    .callback__form:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .callback__input {
    float: left;
    border: none;
    font-size: 35px;
    width: 360px;
    height: 72px;
    margin-right: 20px;
    padding-left: 24px;
    box-sizing: border-box; }
  .callback__button {
    float: left;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #fff500;
    cursor: pointer;
    width: 191px;
    height: 72px;
    padding: 0; }

.contacts {
  background-image: url("/images/contacts_bg.jpg"); }
  .contacts__wrapper {
    *zoom: 1;
    width: 988px;
    margin: 0 auto;
    padding: 66.5px 0; }
    .contacts__wrapper:after {
      content: '';
      display: table;
      line-height: 0;
      clear: both; }
  .contacts__title {
    float: left;
    font-size: 43px;
    font-weight: bold;
    text-transform: uppercase; }
  .contacts__adress {
    position: relative;
    float: left;
    font-size: 17px;
    margin: 0 25px;
    padding-top: 5px; }
  .contacts__list {
    float: left;
    width: 180px; }
  .contacts__phone {
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    font-weight: bold;
    line-height: 22px; }
  .contacts__distinguish {
    color: #ff6600; }
  .contacts__mail {
    text-decoration: none;
    color: inherit;
    float: left;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px; }

.map {
  border-top: 10px solid #ffa728;
  border-bottom: 10px solid #ffa728;
  width: 100%;
  height: 362px; }
