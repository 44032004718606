/*-- placeholder color --*/
::-webkit-input-placeholder {color: $placeholder_color;}
::-moz-placeholder {color: $placeholder_color;}
:-moz-placeholder {color: $placeholder_color;}
:-ms-input-placeholder {color: $placeholder_color;}

* {-webkit-text-size-adjust: none;}
*:focus, select:focus, input:focus, textarea:focus {outline: none;}

body {
	font-family: $font_family;
	font-size: $fs_pc;
	color: $main_color;
	min-width: $max_width;
}

select, input, textarea {
	font-family: $font-family;
	font-size: $fs_pc;
	color: $main_color;
	-webkit-appearance: none;
}