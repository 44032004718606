.header {
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	&--absolute {
		position: absolute;
		background-color: inherit;
		& .header__wrapper {
			width: 950px; //857px;
			padding: 34px 20px 0;
		}
	}
	&--fixed {
		position: fixed;
		background-color: #fff;
		& .header__wrapper {
			width: 750px;
			padding: 15px 20px;
		}
	}
	&__wrapper {
		@include clearfix;
		margin: 0 auto;
	}
	&__logo {
		float: left;
		margin-right: 35px;
	}
	&__info {
		@include clearfix;
		height: 40px;
		margin: 40px 0 14px;
	}
	&__adress {
		float: left;
		font-size: 17px;
		margin-right: 60px;
	}
	&__phone {
		@include link_style;
		font-size: 33px;
		font-weight: bold;
	}
	&__distinguish {color: #f60;}
	&__navigation {font-size: 22px;}
	&__item {
		@include link_style;
		margin-right: 32px;
		&:last-child {margin-right: 0;}
	}
}