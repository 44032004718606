.bold {font-weight: bold;}
.slick-dots {
	@include clearfix;
	position: absolute;
	bottom: 93px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;
	& li {
		float: left;
		font-size: 0;
		background-color: #f6cd0c;
		cursor: pointer;
		width: 23px;
		height: 5px;
		margin: 0 6.5px;
		transition-duration: 0.3s;
		&.slick-active {background-color: #f60;}
		& button {display: none;}
	}
}

.slider {
	position: relative;
	&__arrow {
		position: absolute;
		top: 50%;
		cursor: pointer;
		width: 38px;
		height: 74px;
		z-index: 6;
		&--prev {
			left: 50px;
			background-image: url('/images/ico/left_arrow.png');
		}
		&--next {
			right: 50px;
			background-image: url('/images/ico/right_arrow.png');
		}
	}
	&__item {position: relative;}
	&__img {width: 100%;}
	&__wrapper {
		@include clearfix;
		position: absolute;
		bottom: 135px;
		left: 50%;
		transform: translateX(-50%);
		width: 897px;
	}
	&__info {
		position: relative;
		float: right;
		background-color: rgba(0, 0, 0, .54);
		width: 580px;
		padding: 54px 61px 35px;
		box-sizing: border-box;
		z-index: 5;
		&:after {
			content: '';
			position: absolute;
			bottom: -17px;
			left: 0;
			background-image: url('/images/slide_info_border.png');
			width: 100%;
			height: 17px;
		}
	}
	&__title {
		font-size: 33px;
		font-weight: bold;
		color: #fddf10;
		margin-bottom: 18px;
	}
	&__text {
		color: #fff;
	}
}

.reasons {
	position: relative;
	background-color: #fddf10;
	&:after {
		content: '';
		position: absolute;
		top: -50px;
		left: 0;
		background-image: url('/images/reasons_border.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		width: 100%;
		height: 50px;
	}
	&__wrapper {
		width: 860px;
		margin: 0 auto;
		padding: 76px 0 111px;
	}
	&__title {
		position: relative;
		font-size: 33px;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 57px;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-330px, -50%);
			background-color: #000;
			width: 127px;
			height: 2px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(330px, -50%);
			background-color: #000;
			width: 127px;
			height: 2px;
		}
	}
	&__list {@include clearfix;}
	&__item {
		float: left;
		width: 187px;
		margin-right: 68px;
		&:nth-child(2n+1) {width: 140px;	}
		&:last-child {margin-right: 0;}
	}
	&__img {
		display: inline-block;
		text-align: center;
		width: 100%;
		line-height: 100px;
		margin-bottom: 23px;
		& img {vertical-align: bottom;}
	}
}

.about {
	background-image: url('/images/today_bg.jpg');
	&__wrapper {
		@include clearfix;
		width: 897px;
		margin: 0 auto;
		padding: 93px 0 102px;
	}
	&__img {float: left;}
	&__title {
		font-size: 33px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 48px;
	}
	&__info {padding-left: 306px;}
	&__text {margin-bottom: 20px;}
	&__contacts {@include clearfix;}
	&__adress {
		position: relative;
		float: left;
		font-size: 17px;
		padding: 9.5px 0 9.5px 56px;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background-image: url('/images/ico/point.png');
			width: 40px;
			height: 53px;
		}
	}
	&__phone {
		@include link_style;
		float: right;
		font-size: 32px;
		font-weight: bold;
		line-height: 53px;
	}
	&__distinguish {color: #009414;}
}

.services {
	@include clearfix;
	position: relative;
	overflow: hidden;
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 45%;
		min-width: 600px;
		min-height: 100%;
		&--right {
			left: inherit;
			right: 0;
		}
	}
	&__info {
		position: relative;
		float: right;
		color: #fff;
		width: 55%;
		min-width: 645px;
		z-index: 1;
		&--green {background-color: #009414;}
		&--black {
			float: left;
			background-color: #000;
		}
		&--yellow {
			background-color: #fddf10;
			color: #000;
		}
		&--dark {
			float: left;
			background-color: #2a2a2a;
		}
	}
	&__wrapper {
		@include clearfix;
		width: 529px;
		padding: 104px 58px 105px;
		&--left {float: right;}
	}
	&__title {
		font-size: 33px;
		font-weight: bold;
		color: #ff0;
		margin-bottom: 47px;
		&--black {color: #000;}
	}
	&__text {
		font-size: 16.6px;
		margin-bottom: 18px;
	}
	&__list {
		font-size: 16.6px;
		margin-bottom: 18px;
	}
	&__button {
		display: inline-block;
		border: 1px solid #ff0;
		font-size: 19.5px;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		color: #ff0;
		width: 274px;
		margin-top: 22px;
		padding: 24px 0;
		&--black {
			border-color: #000;
			color: #000;
		}
		&--white {
			position: relative;
			top: 15px;
			border-color: #fff;
			color: #fff;
		}
	}
}

.catalog {
	@include clearfix;
	position: relative;
	overflow: hidden;
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-width: 1600px;
		min-height: 100%;
		&--right {
			left: inherit;
			right: 0;
		}
		&--small {
			min-width: 45%;
			width: auto;
			right: 0;
			left: inherit;
		}
	}
	&__info {
		position: relative;
		float: right;
		color: #000;
		width: 55%;
		min-width: 645px;
		z-index: 1;
		&--left {float: left;}
		&--white {
			background-color: rgba(255, 255, 255, .9);
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: -103px;
				background-image: url('/images/border_white.png');
				    background-size: 100% 100%;
				width: 103px;
				height: 100%;
			}
		}
		&--red {
			background-color: rgba(189, 9, 0, .92);
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: -103px;
				background-image: url('/images/border_red.png');
				background-size: 100% 100%;
				width: 103px;
				height: 100%;
			}
			.catalog__wrapper {float: right;}
		}
		&--green {
			background-color: #009414;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: -101px;
				background-image: url('/images/border_green.png');
				background-size: 100% 100%;
				width: 101px;
				height: 100%;
			}
			.catalog__wrapper {float: right;}
		}
	}
	&__wrapper {
		@include clearfix;
		position: relative;
		width: 529px;
		padding: 94px 20px 75px 156px;
	}
	&__title {
		font-size: 33px;
		font-weight: bold;
		margin-bottom: 55px;
		&--yellow {color: #ffff00;}
		&--white {color: #fff;}
		&--second {margin: 35px 0 31px;}
	}
	&__emb {
		position: absolute;
		top: 130px;
		right: 220px;
	}
	&__item {
		position: relative;
		font-size: 16px;
		line-height: 20px;
		color: #000;
		margin-bottom: 10px;
		padding-left: 35px;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background-image: url('/images/ico/catalog_black.png');
			width: 20px;
			height: 20px;
		}
		&--white {
			color: #fff;
			&:before {background-image: url('/images/ico/catalog_white.png');}
		}
	}
	&__link {
		@include link_style;
		&:hover {text-decoration: underline;}
	}
	&__button {
		display: inline-block;
		border: 1px solid #000;
		font-size: 19.5px;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		color: #000;
		width: 274px;
		margin-top: 53px;
		padding: 24px 0;
		&--light {
			border-color: #fff;
			color: #ff0;
		}
	}
}

.c {
	&-list {
		@include clearfix;
		font-size: 16.6px;
		color: #fff;
	}
	&-item {
		position: relative;
		float: left;
		width: 50%;
		margin-bottom: 10px;
		padding-left: 34px;
		box-sizing: border-box;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background-image: url('/images/ico/calculation.png');
			width: 13px;
			height: 13px;
		}
	}
	&-link {
		@include link_style;
		&:hover {text-decoration: underline;}
	}
}

.partners {
	&__wrapper {
		width: 1253px;
		margin: 0 auto;
		padding: 125px 0 86px;
	}
	&__title {
		position: relative;
		font-size: 33px;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 72px;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-280px, -50%);
			background-color: #000;
			width: 127px;
			height: 2px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(280px, -50%);
			background-color: #000;
			width: 127px;
			height: 2px;
		}
	}
	&__slider {
		position: relative;
		padding: 0 113px;
		box-sizing: border-box;
	}
	&__img {margin: 0 auto;}
	&__arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		width: 63px;
		height: 35px;
		&--prev {
			left: 0;
			background-image: url('/images/ico/slider_arrow_left.jpg');
			width: 61px;
		}
		&--next {
			right: 0;
			background-image: url('/images/ico/slider_arrow_right.jpg');
		}
	}
}

.callback {
	text-align: center;
	background-image: url('/images/callback_bg.jpg');
	margin: 0 auto;
	padding: 88px 0 100px;
	&__title {
		position: relative;
		font-size: 33px;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 28px;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-350px, -50%);
			background-color: #fff;
			width: 127px;
			height: 2px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(350px, -50%);
			background-color: #fff;
			width: 127px;
			height: 2px;
		}
	}
	&__caption {
		font-weight: bold;
		color: #fefe00;
		margin-bottom: 49px;
	}
	&__form {
		@include clearfix;
		width: 967px;
		margin: 0 auto;
	}
	&__input {
		float: left;
		border: none;
		font-size: 35px;
		width: 360px;
		height: 72px;
		margin-right: 20px;
		padding-left: 24px;
		box-sizing: border-box;
	}
	&__button {
		float: left;
		border: none;
		font-weight: bold;
		text-transform: uppercase;
		background-color: #fff500;
		cursor: pointer;
		width: 191px;
		height: 72px;
		padding: 0;
	}
}

.contacts {
	background-image: url('/images/contacts_bg.jpg');
	&__wrapper {
		@include clearfix;
		width: 988px;
		margin: 0 auto;
		padding: 66.5px 0;
	}
	&__title {
		float: left;
		font-size: 43px;
		font-weight: bold;
		text-transform: uppercase;
	}
	&__adress {
		position: relative;
		float: left;
		font-size: 17px;
		margin: 0 25px;
		padding-top: 5px;
	}
	&__list {
		float: left;
		width: 180px;
	}
	&__phone {
		@include link_style;
		font-size: 20px;
		font-weight: bold;
		line-height: 22px;
	}
	&__distinguish {color: #ff6600;}
	&__mail {
		@include link_style;
		float: left;
		font-size: 20px;
		font-weight: bold;
		margin-top: 10px;
	}
}

.map {
	border-top: 10px solid #ffa728;
	border-bottom: 10px solid #ffa728;
	width: 100%;
	height: 362px;
}