.footer {
	background-image: url('/images/footer_bg.jpg');	
	&__wrapper {
		@include clearfix;
		width: 805px;
		margin: 0 auto;
		padding: 36px 20px 31px;
	}
	&__logo {
		float: left;
		display: inline-block;
		border-right: 1px solid #a4a3a3;
		line-height: 67px;
		margin-right: 18px;
		padding-right: 17px;
		& img {vertical-align: middle;}
	}
	&__copyright {
		float: left;
		color: #a4a3a3;
	}
	&__development {
		float: right;
		line-height: 67px;
		& img {vertical-align: middle;}
	}
}