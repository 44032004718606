@mixin clearfix {
  *zoom: 1;
  &:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both;
  }
}

@mixin ghostVerticalAlign(){
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: .1px;
    height: 100%;
  }
}

@mixin link_style {
  text-decoration: none;
  color: inherit;
}